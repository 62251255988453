.reset-password-container {
    display: flex;
    flex-direction: column;
    padding: 40px;
  }
  
  .reset-form {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 12%;
    top: 20%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .reset-button {
    background-color: #000300;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 0;
  }
  /* .form-field {
    margin: 10px 0;
    padding: 15px;
    font-size: 16px;
    border: 0;
    border-radius: 8px;
  } */
  .reset-button:hover {
    background-color: #001a00;
  }
  
  .error-message {
    color: red;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .reset-header {
    font-size: 24px; /* Adjust the font size as needed */
    margin-bottom: 15px; /* Add margin for spacing */
    color: #333; /* Change the color if needed */
    font-weight: bold; /* Add or remove font weight as desired */
  }
  