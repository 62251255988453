.preview-page{
    
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
.preview-page-desc{
    margin: 0px 50px;
}
@media (max-width: 480px){
    .preview-page-desc{
        margin: 50px 0px;
    }
} 

/* Hides the heart icon on the Preview Page only */
.preview-page .set-card-fav-button-container {
    display: none !important;
  }
  
  
  