.individual-item-card{
    width: 250px;
    height: fit-content;
    padding: 0;
    margin: 5px;
    position: relative;
    border-radius: 5px;
}
.individual-item-card-image{
    width: 100%;
    border-radius: 5px;
}
.individual-item-card-desc-container{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 7;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    border-radius: 5px;
    padding: 20px;
    
}
.individual-item-card-desc-name, .individual-item-card-desc-brand{
    color: white;
}
.individual-item-card-desc-container:hover{
    opacity: 1;
    z-index: 1;
}
.individual-item-card-desc-url svg{
    fill: white;
    width: 30px;
}