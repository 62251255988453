.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid with flexible column width */
  gap: 20px; /* Spacing between cards */
  justify-items: center; /* Center items horizontally */
  padding: 1rem;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: sans-serif;
  width: 100%; /* Ensure cards take full width of their container */
  padding: 20px; /* Add padding for better spacing */
}

.product-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: sans-serif;
  max-height: 85vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content and buttons are spaced out */
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.product-image-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-img {
  width: 100%; /* Set width to 100% for responsive scaling */
  height: auto;
  max-width: 12rem;
  max-height: 12rem;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Ensure text and buttons stick to the bottom */
  width: 100%;
  padding: 20px;
  text-align: center;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto; /* Ensure buttons are always at the bottom */
}

.button {
  width: 48%; /* Ensure both buttons take up equal space */
  padding: 10px;
  font-size: 14px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

.product-scroll-status {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust columns for large tablets */
  }

  .product-card {
    padding: 15px;
  }

  .product-img {
    max-width: 10rem;
    max-height: 10rem;
  }

  .button {
    font-size: 13px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Adjust columns for tablets */
  }

  .product-card {
    padding: 10px;
  }

  .product-img {
    max-width: 8rem;
    max-height: 8rem;
  }

  .button {
    font-size: 12px;
    padding: 7px;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Single-column layout for mobile devices */
  }

  .product-card {
    padding: 10px;
  }

  .product-img {
    max-width: 6rem;
    max-height: 6rem;
  }

  .button {
    font-size: 11px;
    padding: 6px;
  }
}
