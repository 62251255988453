@keyframes scroll {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-100%);
    }
  }
  
  .animate-images-scroll {
    animation: scroll 20s linear infinite; /* Adjust duration as needed */
  }

  .bottom-container {
    padding-bottom: 20rem; 
    padding-top: 4rem;
  }

  @media (min-width: 640px) { 
    .bottom-container  {
      padding-bottom: 10rem; 
      padding-top: 6rem;     
    }

    .bottom-text {
        font-size: 3.75rem; 
    }
  }
  
  @media (min-width: 992px) {
    .bottom-container  {
      padding-bottom: 12rem; 
      padding-top: 10rem;   
    }
  }
  