body {
    background: #f8f9e8;
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  .navbar {
    background: black;
    padding: 10px;
    color: white;
  }
  
  .navbar a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .signup-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
  }
  
  .form-container {
    width: 360px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    padding: 10px;
    position: absolute;
    top:20%;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
  }
  
  .success-message {
    background-color: #3f89f8;
    color: white;
    padding: 15px;
    font-size: 16px;
  }
  
  .form-field {
    margin: 10px 0;
    padding: 15px;
    font-size: 16px;
    border: 0;
  }
  
  span {
    font-size: 14px;
    color: red;
    margin-bottom: 15px;
  }
  
  .error {
    border: 2px solid #ffa4a4;
  }
  
  button {
    background: #000300;
    color: white;
    cursor: pointer;
  }
  
  button:disabled {
    cursor: default;
  }
  
  .image-container {
    max-width: 360px; /* Set a maximum width for the image container */
    margin-right: 20%; /* Adjust margin-right to create space between the form and the image */
  }
  
  .image {
    width: 35%;
    height: 35%;
    position: absolute;
    right: 10%;
    top: 20%;
  }
  