.profile-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-container h1,
.profile-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

.profile-picture-section {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #007bff;
  margin-bottom: 1rem;
}

.profile-picture-section input[type='file'] {
  display: block;
  margin: 0.5rem auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
}

/* Save Button */
.primary {
  background-color: rgb(32, 116, 212);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.primary:hover {
  background-color: #0056b3;
}

/* Delete Account Button */
.btn-danger {
  background-color: rgba(242, 46, 46, 0.824);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}

/* Button Group to align Save and Delete buttons next to each other */
.button-group {
  display: flex;               /* Flexbox to align buttons horizontally */
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px;                   /* Adds a small gap between the buttons */
  margin-top: 1.5rem;
  flex-wrap: nowrap; 
}

/* Styles for Change Password Button */
.change-password-btn {
  background: none;
  color: #040507; /* Set the color for text */
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.change-password-btn:hover {
  color: #6f7377; /* Darker color on hover */
}

.change-password-btn {
  background-color: rgba(195, 189, 189, 0.731);
  color: rgb(30, 29, 29);
  font-weight: 400;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* General styling for small screens */
@media (max-width: 600px) {
  .profile-container {
    padding: 1rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .btn-primary {
    font-size: 1rem;
  }
}
 