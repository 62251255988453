
.custom-isolate {
    isolation: isolate;
  }
  
  .custom-responsive-padding {
    padding-left: 1.5rem; 
    padding-right: 1.5rem;
    padding-top: 4rem; 
  }
  
  .custom-grid-layout {
    display: block; 
  }

.custom-text-4xl {
    font-size: 2.25rem; /* 36px */
    line-height: 1.5rem
  }
  
  .custom-tracking-tight {
    letter-spacing: -0.05em;
  }
  
  .custom-leading-10 {
    line-height: 2.5rem; /* 40px */
  }

  .custom-max-width {
    max-width: 768px;
  }

  .get-started-div {
    gap: 1.5rem;
    justify-content: flex-start; 
    margin: auto;
  }

  .get-started-btn {
    background-color: black;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 1.3rem;
    border-color:azure;
    font-size: 12px;
    margin: 1rem;
  }

  .get-started-btn:hover{
    background-color: #e5e7eb;
    color: black;
  }

  .get-started-btn:focus-visible{
    outline: 2px solid white;
    outline-offset: 2px;
  }

  .play-link {
    line-height: 1.5;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .play-link-btn {
    font-size: 2.25rem;
    position: relative;
  }

  .position-relative {
    position: relative;
  }

  .bg-container {
    top: 8rem;
    height: 25rem;
    width: 35rem;
    border-radius: 150px;
    transition: all 0.3s ease-in-out;
  }

  .group:hover .bg-container {
    transform: scale(1.05);
  }

  .custom-img-cover {
    
    object-position: center; 
  
  }
  



  @media (min-width: 576px) { 
    .custom-responsive-padding {
      padding-left: 4rem; 
      padding-right: 4rem;
    }

    .custom-text-4xl {
        font-size: 3rem; /* 48px */
      }
  }
  
  @media (min-width: 768px) { 
    .custom-responsive-padding {
      padding-top: 6rem; 
    }
  }

  @media (min-width: 992px) { 
    .custom-large {
        margin-left: 10rem;
        margin-right: 10rem;
        padding-top: 8rem;
        padding-bottom: 8rem;
        text-align: left; 
        transform: scale(1.5); 
        flex: 1; 
      }

    .custom-grid-layout {
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      gap: 2.5rem; 
      padding-left: 6rem; 
      padding-right: 6rem;
      padding-top: 0; 
    }
  }
  