.items-card {
    max-width: 812px;
    width: fit-content;
    min-width: 760px;
    height: 750px;
    padding: 10px;
    margin: 30px;
    overflow-y: scroll;
    background-color: lightgray;
    border-radius: 5px;
}


.items-card-gender-option{
  margin: 10px;
  height: fit-content;

}

/* Custom scrollbar for items-card with right margin */
.items-card::-webkit-scrollbar {
    width: 12px; /* Adjust width to include the "margin" */
  }
  
  .items-card::-webkit-scrollbar-thumb {
    background-color: #555; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Make it rounded */
    border: 2px solid transparent; /* Transparent border to create space */
    box-shadow: inset 0 0 0 2px #555; /* Keeps the visible size correct */
  }
  
  .items-card::-webkit-scrollbar-track {
    background: transparent; /* Invisible track */
  }
  
  /* For Firefox (this approach is limited) */
  @-moz-document url-prefix() {
    .items-card {
      scrollbar-width: thin; /* Thin scrollbar */
      scrollbar-color: #555 transparent; /* Thumb color and transparent track */
    }
  }