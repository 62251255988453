.set-card{
    
    width: 250px;
    height: fit-content;
    margin: 30px;
    border-radius: 5px;
    padding: 0;
    position: relative;
    
}
.set-card-desc-container{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 7;
}

.set-card-desc-container:hover{
    
    opacity: 1;
    z-index: 1;
}

.set-card-desc{
    width: 100%;
    height: 50%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    border-radius: 5px;
    padding: 40% 10px 20px 10px;

}

.set-card-madeBy-container, .set-card-name-container{
    height: fit-content;
}

.set-card-desc h3, .set-card-desc p{
    color: white;
}

.set-card-nameAndMadeBy-container{
    margin: 0;
    padding: 0;
    height: 70%;
}

.set-card-icons-container{
    margin: 10px 0;
    padding: 0;
    height: 65%;
}
.set-card-top-image{
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    
    margin: 0px;
    padding: 0;

}
.set-card-top-image img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}
.set-card-bottom-image{
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding: 0;
    height: min-content;
}
.set-card-bottom-image img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    
}
.set-card-shoes-image{
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position:bottom;
    width: 100%;
    height: 30%;
    padding: 0;
    
}
.set-card-shoes-image img{
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: 0 90%;
    border-radius: 0 0 5px 5px;
}

.set-card-fav-button, .set-card-edit-button, .set-card-add-button {
    cursor: pointer;
    width: 100%;
    
    margin: 0;
    
}
.set-card-vote-count{
  margin: 0;
  margin-bottom: 20px;
}
.set-card-fav-button svg{
    stroke: white;
}
.set-card-fav-button-container, .set-card-edit-button-container, .set-card-add-button-container{
    width: fit-content;
    height: fit-content;
}

/* CSS for the container holding the Favorite/Unfavorite buttons */
.set-card-favorite-toggle {
    display: flex;
    gap: 4px; /* Adjust this value to control the space between buttons */
    justify-content: start;
    margin-top: 8px;
    width: 100%;
  }
  
  /* CSS for individual buttons */
  .favorite-btn, .unfavorite-btn {
    font-size: 0.9rem;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #edebeb;
    color: #333;
    margin: 0; /* No additional margin on buttons */
  }
  
  /* Active state styles */
  .favorite-btn.active, .unfavorite-btn.active {
    background-color: #484848;
    color: white;
  }

  .set-card-preview{
    width: 300px;
    
    padding: 0;
    border-radius: 5px;
    background-color: #333;
  }
  
  .set-card-preview-image-container{
    padding: 0;
  }
.set-card-preview-icon-container{
    padding: 10px 5px;
    }
  .set-card-preview-icons-container{
    padding: 5px;
    height: 95%;
  }
  
  .set-card-preview-icons-container{
    margin: 10px 0;
    padding: 0;
  }
  .set-card-mix-and-match{
    width: 250px;
    height: fit-content;
    margin: 30px;
    border-radius: 5px;
    padding: 0;
    position: relative;
  }
  .set-card-mix-and-match img{
    background-color: #d6d6d6;
    border-color: white;
    border-style: solid;
    border-width: 1px;
  }
  .set-card-shoes-image-mix-and-match, .set-card-bottom-image-mix-and-match, .set-card-top-image-mix-and-match{
    cursor: pointer;
  }
  .vote-count {
    font-size: 14px;
    color: white;
    text-align: center;
   
  }