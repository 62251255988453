/* Styles for Sign in Button */
.custom-signin-button,
.custom-signup-button {
    border-radius: 1rem;
    padding: 0.875rem 2.5rem;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    background-color: #1f2937; /* Set the background color for both buttons */
    color: white; /* Set the text color for both buttons */
    border: none; 
}

.custom-signin-button:hover,
.custom-signup-button:hover {
    background-color: #1f2937;
}

.custom-chevron {
    position: absolute;
    top: 1rem;
    right: 2rem;
    width: 1.25rem;
    height: 1.25rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s;

}

.group:hover .custom-chevron,
.group-signup:hover .custom-chevron {
    right: 0.25rem;
}
/* Styles for Log out Button */
.custom-logout-button {
  border-radius: 1rem;
  padding: 0.875rem 2.5rem;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  background-color: #1f2937; /* Set the background color for the logout button */
  color: white; /* Set the text color for the logout button */
  border: none; 
}

.custom-logout-button:hover {
  background-color: #1f2937;
}

.custom-logout-button .custom-chevron {
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 1.25rem;
  height: 1.25rem;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.custom-create{
  
  padding: 0.875rem 2.5rem;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  color: white; /* Set the text color for both buttons */
  border: none; 
}



/* Styles for the dropdown menu */
.navbar-dark .dropdown-menu {
  background-color: black; /* Set background to black */
  border: none;
  width: auto; /* Ensure the dropdown menu width adjusts to its content */
  min-width: 10rem; /* Set a minimum width for consistency */
}

/* Styles for dropdown items */
.navbar-dark .dropdown-item {
  color: white; /* Set text color to white by default */
  padding: 0.5rem 1rem; /* Adjust padding for better spacing */
  white-space: nowrap; /* Prevent text from wrapping to multiple lines */
}

.navbar-dark .dropdown-item:hover {
  background-color: #343a40; /* Darken background on hover */
  color: white; /* Ensure text color remains white on hover */
  border-radius: 0.25rem; /* Optional: Add rounded corners on hover */
  width: auto;

}